/* BreakDown */
/* 
.div-close-button-customer{
  margin: 8px 0px 15px 15px;
  background-color: #f4f5f5;
  border-radius: 8px;
  width: 44px;
  height: 44px;
  cursor: pointer
} */

.scrollbar-dialog {
  padding: 0 24px 30px 24px;
  /* overflow-x: hidden;
  overflow-y: scroll; */
}
/* .scrollbar-dialog::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 500px;
} */

.signin-div-close-button{
  margin: 8px 8px 15px 15px;
  background-color: #f4f5f5;
  border-radius: 8px;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.scrollbar-dialog::-webkit-scrollbar-thumb {
  background: #e0e0e0;
}

.breakdown-container {
  background-color: #ffff;
  border-radius: 8px;
  /* min-height: 522px; */
  width: 100%;
  padding: 0 0px 30px 0;
  margin: 0px 0 24px 0;
}
.breakdown-sub-container {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.breakdown-heading {
  display: flex;
  padding: 24px 0 0 24px;
}
.signing-heading {
  margin-top: 10px;
}

.css-mluzm9 {
  padding: 51px;
}

.question-mark {
  margin-top: 2px;
  margin-left: 12px;
}
.moreverticon {
  margin: 15px 0px 0 2px;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
}
.moreverticon-Customer {
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 44px;
  width: 44px;
}
.grid-container {
  display: flex;
}

.total-registrations {
  text-align: center;
  margin-top: 25px;
}

.data-events {
  /* margin-top: 10px; */
  display: flex;
  align-items: center;
}
.customer-heading {
  margin-top: 10px;
  margin-right: 15px;
}

/* customer journey */

.table-section {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 14px;
  height: fit-content;
}

.container-age {
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 0 0px 24px 24px;
}

.chart-section {
  padding: 15px;
}

.breakdown-customer-btn {
  background-color: #fff;
  padding: 5px;
  width: 103px;
  height: 34px;
  border-radius: 8px;
  border: 1px solid #f4f5f5;
}

.css-11eiqkg {
  margin-top: 20px !important;
}

/* transaction room */

.room-container {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  position: relative;
}

.card-container{
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  margin: 24px;
}


.region-name{
  padding: 24px 0 0 24px;
}

.heading-age {
  width: max-content;
}

.morevertIcon-room {
  margin: 10px;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
}

/* transaction  */

.transaction-container {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  position: relative;
}

.chart-container {
  width: auto;
}

.apexcharts-theme-light {
  width: auto;
}

/* customer-journey */

.css-1bt02ar-MuiTableCell-root {
  padding-right: 0px !important;
}
