@font-face {
  font-family: "Cerebri sans-bold";
  src: url(./fonts/Hanken\ Design\ Co.\ -\ Cerebri\ Sans\ Bold.otf);
}

@font-face {
  font-family: "Cerebri sans-semi-bold";
  src: url(./fonts/Hanken\ Design\ Co.\ -\ Cerebri\ Sans\ SemiBold.otf);
}
@font-face {
  font-family: "Cerebri sans-medium";
  src: url(./fonts/Hanken\ Design\ Co.\ -\ Cerebri\ Sans\ Medium.otf);
}
@font-face {
  font-family: "Cerebri sans-regular";
  src: url(./fonts/Hanken\ Design\ Co.\ -\ Cerebri\ Sans\ Regular.otf);
}
@font-face {
  font-family: "Cerebri Sans";
  src: url(./fonts/Hanken\ Design\ Co.\ -\ Cerebri\ Sans\ Regular.otf);
}
@font-face {
  font-family: "Cerebri sans-light";
  src: url(./fonts/Hanken\ Design\ Co.\ -\ Cerebri\ Sans\ Light.otf);
}
@font-face {
  font-family: "Cerebri sans-light-italic";
  src: url(./fonts/Hanken\ Design\ Co.\ -\ Cerebri\ Sans\ LightItalic.otf);
}

body {
  font-family: "Cerebri Sans";
  padding: 0px !important;
  overflow: overlay;
}

/* Menu Tab - Start */
.menu-tabs {
  font-family: "Cerebri Sans-Light";
  font-size: 14px;
  display: inline-block;
  list-style: none;
  width: auto;
  padding: 14px 28px;
  border: 1px solid transparent;
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}

.menu-tabs .react-tabs__tab--selected {
  font-family: "Cerebri Sans-Bold";
  background: #fff;
  color: #619130;
  border: 1px solid #f4f5f5;
}

.menu-tabs:not(.react-tabs__tab--selected):hover {
  background: #fff;
  color: #619130;
  border: 1px solid #f4f5f5;
}
/* Menu Tab - End */

/* All Properties Overview */
.all-properties-overview {
  display: flex;
  padding: 25px 32px;
  align-items: center;
}

/*Padding x */
.padding-x10 {
  padding: 10px 0px;
}
.margin-heading-card {
  padding: 24px 0 0 24px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.sticky {
  top: 0;
  z-index: 1;
  position: sticky;
}

/* Fonts */

.bold {
  font-family: Cerebri sans-bold;
}
.semi-bold {
  font-family: Cerebri sans-semi-bold;
}

.medium {
  font-family: Cerebri sans-medium;
}

.regular {
  font-family: Cerebri Sans;
}
.light {
  font-family: Cerebri sans-light;
}
.light-italic {
  font-family: Cerebri sans-light-italic;
}

.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}

.line-height-18 {
  line-height: 18px;
}
.line-height-19 {
  line-height: 19px;
}
.line-height-20 {
  line-height: 20px;
}
.line-height-21 {
  line-height: 21px;
}
.line-height-22 {
  line-height: 22px;
}
.line-height-23 {
  line-height: 23px;
}
.line-height-24 {
  line-height: 24px;
}

.cursor {
  cursor: pointer;
}

.whitespace {
  white-space: nowrap;
}

.padding-15px {
  padding: 15px;
}

.margin-20 {
  margin: 20px;
}

.margin-top-3px {
  margin-top: 3px;
}

.shadow-custom {
  box-shadow: 0 -3px 15px 0 rgba(0, 0, 0, 0.25);
}

.shadow-custom-sm {
  box-shadow: 0 -3px 15px 0 rgba(0, 0, 0, 0.08);
}

.shadow-modal {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.25);
}

.shadow-popup {
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.3);
}

.filter-drop-shadow {
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.04));
}
.primary-color {
  color: #619130;
}

.position-relative {
  position: relative;
}

.display-inline {
  display: inline;
}

.display-absolute {
  display: absolute;
}

.display-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.line-height-20 {
  line-height: 20px;
}

.title-case {
  text-transform: capitalize;
}

.download-widget {
  cursor: pointer;
  margin-top: 16px;
  margin-right: 16px;
  margin-bottom: 8px;
  margin-left: 16px;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
  transform: none;
  box-shadow: none;
}

.download-widget:hover {
  box-shadow: 0px 2px rgba(0, 0, 0, 0.04);
}

.download-widget.active {
  box-shadow: 2px 0px rgba(0, 0, 0, 0.04);
  transform: rotate(90deg);
}

.user-icon-color {
  fill: #b4c2a6 !important;
}
.download-widget:hover .user-icon-color,
.download-widget.active .user-icon-color {
  fill: #619130 !important;
}

.user-icon-color {
  fill: #b4c2a6 !important;
}
.download-widget:hover .user-icon-color,
.download-widget.active .user-icon-color {
  fill: #619130 !important;
}

td:first-child,
th:first-child {
  border-radius: 4px 0 0 4px;
}

td:last-child,
th:last-child {
  border-radius: 0 4px 4px 0;
}

.gQvQMS {
  background-color: rgba(0, 0, 0, 0.02) !important;
}

/* Scrollbar */
.scrollable-container {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  margin-right: 6px !important;
  /* scrollbar-width: thin; */
}
.scrollable-container::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 100px;
  overflow: visible;
}
.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 100px;
}
.scrollable-container::-webkit-scrollbar-track {
  background-clip: padding-box;
}

.uppercase {
  text-transform: uppercase;
}

.pagination-style {
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #619130; */
  color : #fff;
  border-radius: 4px;
  border: none;
  padding: 4px;
  cursor: pointer;
}

button {
  border: none;
}

.cursor-pointer {
  cursor: pointer;
}
