/* Completed Events */

.scroller {
  width: 100%;
  /* height: 456px;
  padding: 0 24px; */
  overflow-x: hidden;
  overflow-y: scroll;
}

.scroller::-webkit-scrollbar {
  width: 5px;
  border-radius: 500px;
}

.scroller::-webkit-scrollbar-thumb {
  background: #e0e0e0;
}

.info-drop {
  height: 30px;
  padding: 5px;
}

.Editinfo-text-field {
  height: 42px;
  width: 196px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px px solid #f4f5f5;
  padding-left: 16px;
}

.event-heading {
  display: flex;
  justify-content: space-evenly;
  margin-left: 236px;
  padding: 0 10px;
}

/* mui popup */

.events-container {
  margin: 7px 25px 10px 20px;
  height: 88px;
  border-radius: 8px;
  background-color: #f9fafa;
  display: flex;
  justify-content: space-between;
  font-family: "Regular";
  align-items: center;
  width: 97%;
}

.events-container-particpant {
  /* height: 88px; */
  border-radius: 8px;
  /* background-color: #f9fafa; */
  /* display: flex; */
  /* width: 100%; */
  /* justify-content: space-between; */
  font-family: "Regular";
  align-items: center;
}

.events-name {
  line-height: 1.4;
  flex-grow: 0.3;
}

.events-name-child {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.events-date-time {
  font-size: 10px;
  font-weight: 300;
  line-height: 16px;
}

.registered-data {
  display: flex;
  justify-content: flex-start;
  width: 500px;
  justify-content: space-around;
  width: 700px;
  margin-left: 105px;
}

.registered-data-value {
  display: flex;
  font-family: "Bold";
}

.registered-arrow {
  font-size: 10px;
  margin: 0 0 8px 5px;
  color: #77c7a1;
  font-family: "Regular";
}

.participated-value {
  display: flex;
  font-family: Bold;
}

.participated-percentage {
  font-size: 10px;
  margin: 0 0 8px 5px;
  color: #f1664b;
  font-family: "Regular";
}

.units-reserved {
  display: flex;
  align-items: baseline;
}

.units-reserved-value {
  color: #619130;
}

.MoreVertIcon-container {
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
}

.MoreVertIcon {
  position: relative;
  top: 10px;
  left: 10px;
  /* padding: 3px 37px 22px 0; */
}

.events {
  background-color: #fff;
  border-radius: 10px;
  margin: 2px 0 16px 0;
  /* padding: 10px; */
}

.events-child {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.events-child2 {
  display: flex;
}

.events-MoreVertIcon {
  margin: 10px;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
}

.heading-registered {
  display: flex;
  /* width: 700px; */
  align-items: center;
  justify-content: space-around;
  margin-left: 70px;
  padding: 10px;
}

.vertical-ellipsis {
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
  background-color: #ffff;
  /* margin-bottom:40px ; */
}

/* Completed Events */

/* Future Events */

/* Future Events */

.inner-table-section {
  height: 210px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.inner-table-section::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 500px;
}

.inner-table-section::-webkit-scrollbar-thumb {
  background: #e0e0e0;
}

.paper {
  background-color: #fff;
  border-radius: 10px;
  margin-top: 2%;
}

.buttonEvent {
  background-color: #619130;
  color: #fff;
  height: 44px;
  width: 145px;
  top: 16px;
  border-radius: 8px;
  /* padding: 9px , 17px , 9px , 17px; */
  /* gap: 12px; */
}

.futureEvent {
  font-family: "Regular";
  font-size: 14px;
  margin-top: 15px;
  margin-left: 13px;
}

label {
  display: block;
}

.tab-list {
  margin-left: "200px";
  justify-content: space-around;
}

.css-5yjhr3-MuiPaper-root-MuiCard-root {
  margin-bottom: 16px !important;
}

.MuiGrid-item {
  margin-top: 0;
}

/* Create New Events */

.new-events-container {
  padding-bottom: 10px 10px 0 10px;

  height: fit-content;
  width: 1088.5px;
  background-color: #fff;
  margin: auto;
  border-radius: 8px;
}

.new-events-subcontainer {
  display: flex;
  height: 64px;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  position: relative;
  bottom: 16px;
  background-color: #fff;
}

.new-events-header {
  display: flex;
  margin: 20px;
}

.new-events-que-mrk {
  margin-top: 0px;
  margin-left: 12px;
}

.new-events-moreovericon {
  margin: 8px 15px 8px 15px;
  background-color: #eff0f0;
  border-radius: 8px;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.hr-line {
  padding-bottom: 30px;
  border: 1px solid #f4f5f5;
  background-color: #fff;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.event-basic {
  padding-left: 36px;
  margin-bottom: 10px;
}

.event-basic-data {
  padding-left: 34px;
  display: flex;
  margin-top: 30px;
}

.type-drop-down {
  min-height: fit-content;
  /* width: fit-content; */
  min-width: 190px;
  border-radius: 8px;
  position: absolute;
  background-color: #fff;
  /* padding: 15px; */
  line-height: 30px;
  z-index: 1;
  margin-top: 53px;
}

.repeat-drop-down {
  min-height: fit-content;
  min-width: 190px;
  border: 1px solid rgb(244, 245, 245);
  border-radius: 8px;
  position: absolute;
  background-color: #fff;
  line-height: 30px;
  z-index: 1;
  margin-top: 9px;
  right: 540px;
  width: 10px;
}

/* menu-item mui class */

.new-event-type {
  background-color: #fff;
  width: 188px;
  height: 44px;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #f4f5f5;
  background-color: #ffffff;
  display: flex;
  text-align: center;
  justify-content: space-between;
  position: relative;
}

.new-event-Repeat {
  background-color: #fff;
  width: 188px;
  height: 44px;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #f4f5f5;
  background-color: #ffffff;
  display: flex;
  text-align: center;
  justify-content: space-between;
  position: relative;
  left: 10px;
}

.new-event-date {
  background-color: #fff;
  width: 220px;
  height: 44px;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #f4f5f5;
  display: flex;
  text-align: center;
  justify-content: space-between;
  margin-top: 31px;
}

.new-event-desc {
  background-color: #fff;
  width: 400px;
  height: 150px;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #f4f5f5;
  display: flex;
  text-align: center;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 20px;
}

.type-event-check {
  display: flex;
}

.event-time-managment {
  display: flex;
  padding: 15px 35px;
}

.count-down-first {
  width: 97px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #f4f5f5;
}

.count-down-second {
  width: 97px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #f4f5f5;
  margin-left: 10px;
}

.repeat {
  width: 226px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #f4f5f5;
  margin-left: 10px;
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding: 10px;
}

.Description-event {
  height: 160px;
  width: 416px;
  margin: 12px 25px 0 0;
  border-radius: 8px;
  border: 1px solid #f4f5f5;
  background-color: #ffffff;
  padding-bottom: 8.4em;
  padding-left: 9px;
}

.Description-event:focus-visible {
  outline: none;
}

.headline-event {
  height: 44px;
  width: 232px;
  /* margin: 12px 25px 0 0; */
  border-radius: 8px;
  border: 1px solid #f4f5f5;
  background-color: #ffffff;
  /* padding-bottom: 8.4em; */
  padding-left: 9px;
}

.headline-event:focus-visible {
  outline: none;
}

.type-event-time {
  margin-left: 11px;
  gap: 11px;
  display: flex;
  justify-content: space-around;
}

.count-down-first-value {
  display: flex;
  padding: 11px;
  justify-content: space-between;
}

.event-title-section {
  /* border: 1px solid black; */
  width: 100%;
  height: 407px;
}

.create-event-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9fafa;
  height: 56px;
  text-align: center;
  padding: 9px 25px;
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 64px;
  border-radius: 0 0 8px 8px;
}

.create-event-btn-btn {
  height: 38px;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: black;
  border-radius: 8px;
  padding: 0 15px;
  width: max-content;
}

.btn-text-no {
  text-align: center;
  margin-top: 10px;
}

.event-btn-green {
  padding: 0 15px;
  width: fit-content;
  height: 38px;
  justify-content: space-between;
  align-items: center;
  background-color: #619130;
  color: #fff;
  border-radius: 8px;
}

.title-desc {
  margin-bottom: 60px;
  padding: 0 30px;
  padding-top: 116px;
}

.headline-event {
  padding-left: 10px;
}

/* DropDown */

.menu-item-repeat {
  height: auto !important;
  width: auto !important;
  border-radius: 8px !important;
}

.menu-item-repeat-border {
  height: auto !important;
  width: auto !important;
  border-radius: 8px !important;
  position: absolute;
}

.edit-popup {
  min-height: fit-content;
  width: 226px;
  border: 1px solid rgb(244, 245, 245);
  border-radius: 8px;
  right: 190px;
  position: relative;
  margin-top: 15px;
  background-color: rgb(255, 255, 255);
}

/* future event */

.hr-line-event {
  border: 1px solid #f4f5f5;
  margin-top: -28px;
}

.info-even-head {
  /* display: flex; */
  /* width: 500px; */
  /* align-items: center; */
  width: 100%;
  padding: 18px 0;
  /* gap: 220px; */
  /* margin-left: 60px; */
  white-space: nowrap;
  /* top: 16px; */
  /* position: relative; */
}

.history-chart-section {
  padding: 51px;
}

.registered-data-events {
  display: flex;
  justify-content: flex-end;
  /* width: 500px; */
  /* justify-content: space-evenly; */
  /* width: 700px; */
  /* border-bottom: none; */
  /* margin-left: 107px; */
}

.assign-to {
  margin-right: 25px;
  margin-top: 20px;
}

.events-name {
  padding-left: 29px;
  white-space: nowrap;
}

.email-event {
  line-height: 25px;
  border: none;
}

/* Edit Future information Data */

.edit-future-container {
  height: 580px;
  width: 100%;
  border: 1px solid #f4f5f5;
  background-color: #f9fafa;
  position: relative;
  margin: auto;
}

.edit-heading {
  margin: 16px 900px 901px 43px;
  position: absolute;
}

.event-fst-row {
  display: flex;
  margin-top: 63px;
  margin-left: 43px;
}

.event-third-row {
  margin-top: 22px;
  margin-left: 42px;
  display: flex;
}

.event-fourth-row {
  margin-top: 22px;
  margin-left: 42px;
  display: flex;
  gap: 16px;
}

.event-second-row {
  display: flex;
  padding-top: 23px;
  margin-left: 43px;
  gap: 15px;
}

.events-last-name {
  margin-left: 16px;
}

.event-second-row-section {
  height: 43px;
  width: 101px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #f4f5f5;
  padding-left: 16px;
}

.new-event-drop-down {
  background-color: #fff;
  height: 43px !important;
  width: 196px !important;
  border-radius: 8px !important;
  padding: 16px;
  border: 1px solid #c3bfbf;
  display: flex;
  text-align: center;
  margin-left: 16px;
  justify-content: space-between;
}

/* information DropDown */

/* datefieldclass Mui*/
/* .css-ess4ju-MuiInputBase-root-MuiOutlinedInput-root {
  height: 51px;
  width: 196px;
  background-color: #fff;
} */
/* .head-unit-type{
  height: 45px;
  width: 196px;

} */

.MuiList-padding {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.btn-extra-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: max-content;
  background-color: #619130;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  letter-spacing: 1px;
  position: absolute;
  z-index: 10;
  margin: 36px 759px 512px 43px;
  padding: 10px;
}

.add-exta-cont {
  margin-right: 5px;
}

.preferred-unit {
  margin-top: 139px;
  margin-left: 40px;
}

.preffered-user-name {
  background-color: #fff;
  margin-top: 26px !important;
  width: 196px;
  height: 43px;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #c3bfbf;
  display: flex;
  text-align: center;
  justify-content: space-between;
  margin-top: 26px;
}

.heard-prop-checkbox-section {
  margin: 112px 0;
}

.heard-prop-checkboxes {
  display: flex;
  margin-top: 30px;
  align-items: center;
  width: 900px;
  flex-wrap: wrap;
}

.add-to-event {
  position: sticky;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f5f5;
  height: 56px;
  text-align: center;
  padding: 0px 25px;
  width: 100%;
  border-radius: 0px 0px 8px 8px ;
  border: 1px solid #f4f5f5;
}

.horizonal-line-event {
  margin-top: 24px;
  background-color: #77c7a1;
}

.stattis-map-section {
  padding: 0 0 27px 27px;
  border-radius: 8px;
}

.table-section {
  height: 480px;
  width: 100%;
  padding-left: 32px;
  padding-right: 18px;
  position: relative;
  bottom: 30px;
}

.table-data {
  height: 420px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.table-data::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 500px;
}

.table-data::-webkit-scrollbar-thumb {
  background: #e0e0e0;
}

.aboutnumbers {
  font-size: 26px;
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 600;
  /* position: absolute; */
  font-size: 26px;
  line-height: 28px;
  /* identical to box height, or 108% */

  letter-spacing: -0.45px;

  color: #000000;
}
.aboutnumbersone {
  /* font-size: 26px; */
  font-family: "Cerebri Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
  /* identical to box height, or 108% */
  /* padding-top: 20px; */
  /* margin-top: 20px; */
  /* position: absolute; */
  /* letter-spacing: -0.45px; */

  color: #000000;
}
.aboutnumberstwo {
  font-size: 26px;
  /* font-family: "Cerebri Sans"; */
  font-style: normal;
  /* font-weight: 600; */
  font-size: 26px;
  line-height: 28px;
  /* position: absolute; */
  /* top: 133px; */
  /* left: 446px; */
  /* identical to box height, or 108% */
  /* padding-top: 20px; */
  /* margin-top: 20px; */
  /* padding-left: 10px; */
  /* z-inde;  position: absolute; */
  letter-spacing: -0.45px;
  margin-left: 0px;

  color: #000000;
}
.mapMarker {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  border: 2px solid #f1664b;
  background-color: rgba(241, 102, 75, 0.5);
  text-align: center;
  padding: 0 5px;
  color: #3f51b5;
  font-size: 16;
  font-weight: bold;
  padding: 4;
  cursor: pointer;
  z-index: 24;
  display: flex;
}

.markerTooltip {
  padding: 9px 0px 2px 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 13px;
  left: -47px;
  border: 1px solid #f4f5f5;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  height: 57px;
  width: 130px;
  text-align: left;
}

.preferred-units {
  width: fit-content;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 10px 10px 20px;
  color: #000;
  border: 1px solid #f4f5f5;
  margin-bottom: 10px;
}
