.milestone-container {
  /* height: 551px; */
  width: 100%;
  margin: auto;
  background-color: #ffff;
  padding-bottom: 30px;
}

.milestone-drop-down {
  min-height: fit-content;
  min-width: 200px;
  border-radius: 8px;
  position: absolute;
  background-color: #fff;
  line-height: 30px;
  z-index: 3;
  margin-top: 169px;
  margin-left: 37px;
  box-shadow: rgb(0 0 0 / 4%) 0px 2px 3px;
  border: 1px solid #f4f5f5;
}

.milestone-header {
  height: 64px;
  border: 1px solid #f4f5f5;
}

.create-milestone-section {
  height: 336px;
  width: 303px;
  border-radius: 8px;
  background-color: rgb(97, 145, 48, 0.1);
  border: 1px solid rgb(97, 145, 48, 0.2);
  margin: 8px;
}

.milestone-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #619130;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  height: 44px;
  width: max-content;
  letter-spacing: 0.75px;
  /* margin: 114px 0 0 38px; */
  /* position: absolute; */
  z-index: 2;
  padding: 10px 20px 10px 10px;
  /* position: relative;
  top: 40%;
  left: 16%; */
}

.milestone-moreovericon {
  /* margin: 8px 8px 15px 15px; */
  background-color: #eff0f0;
  border-radius: 8px;
  width: 44px;
  height: 44px;
  cursor: pointer;
  align-items: center;
}

.milestone-moreovericon {
  /* margin: 10px 15px; */
  background-color: #eff0f0;
  border-radius: 8px;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.milestone-automatic .milestone-manual {
  height: 44px !important;
  width: 210px !important;
  border-radius: 8px !important;
  position: absolute;
}

.milestone-header-subcontainer {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  align-items: baseline;
}

.milestone-header-section {
  display: flex;
  margin: 24px 0 0 24px;
}
.milestone-que-mrk {
  margin-top: 2px;
  margin-left: 12px;
}

.milestone-para {
  padding: 30px 0 30px 30px;
  line-height: 22px;
}

/* automatic-milestone */

.automatic-milestone-container {
  width: 100%;
  /* max-height: 852px; */
  background-color: #fff;
  margin: auto;
}

.automatic-milestone-header {
  display: flex;
  justify-content: space-between;
  border: 1px solid #f4f5f5;
  background-color: #fff;

  z-index: 2 !important;
}

/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 20px !important;
  background-color: #ffffff !important;
  border-radius: 8px !important;
} */

.name-of-milestone {
  display: flex;
  /* justify-content: space-between; */
  /* margin-top: 39px; */
  padding: 46px 56px 35px 10px;
  gap: 118px;
}
.property-of-milestone {
  display: flex;
  /* justify-content: space-between; */
  /* margin-top: 39px; */
  padding: 46px 56px 35px 10px;
  gap: 104px;
}
.Target-date {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}

.Target-date-section {
  display: flex;
  white-space: nowrap;
  margin-left: 10px;
  align-items: flex-start;
  /* position: relative;
    left: 49px; */
}

.switch-target {
  display: flex;
  white-space: nowrap;
  padding: 10px;
  align-items: center;
}

.datefield-toogle-section {
  display: flex;
  align-items: center;
  /* margin-left: -7px; */
  position: relative;
  /* right: 2px; */
  left: 3px;
}
.datefield-toogle-section-manual {
  display: flex;
  align-items: center;
  /* margin-left: -7px; */
  position: relative;
  right: 2px;
}

.target-date-field {
  display: flex;
  gap: 131px;
}
.Status-value-parameter {
  display: flex;
  gap: 26px;
  margin-top: 32px;
}
.Status-value-parameter-manual {
  display: flex;
  gap: 29px;
  /* margin-left: 41px; */
  margin-top: 34px;

  /* gap: 150px;
  padding-left: 45px;
  margin-top: 28px; */
}

.statusparametr-textfield-sub-part {
  display: flex;
  gap: 26px;
  margin-left: 42px;
}

.status-section {
  position: relative;
  right: 81px;
}

.color-textfield {
  margin-left: 88px;
}
.color-textfield-manual {
  margin-left: 53px;
}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  width: 97% !important;
}

.color-of-milestone {
  display: flex;
  padding: 34px 56px 35px 13px;
  gap: 118px;
}
.visibility-of-milestone {
  display: flex;
  gap: 148px;
  padding: 2px 52px 39px 0;
}

.use-as-container {
  display: flex;
  align-items: center;
  gap: 27px;
}

.use-as-container-manual {
  display: flex;
  align-items: center;
  position: relative;
  right: 32px;
}

.auto-milestone-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  width: auto;
  border-radius: 0 0 8px 8px;
  background-color: #f4f5f5;
  padding: 20px;
}

.footer-reset-save-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.footer-cancel {
  width: fit-content;
  height: 44px;
  background-color: white;
  text-align: center;
  border: 1px solid #f4f5f5;
  padding: 12px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.footer-reset-setting {
  width: fit-content;
  height: 44px;
  background-color: #fff;
  border: 1px solid #f4f5f5;
  /* text-align: center; */
  padding: 10px;
  padding: 11px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.footer-save {
  min-width: 86px;
  height: 44px;
  background-color: #619130;
  color: #ffffff;
  padding: 12px 26px;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
}

.name-of-manual-milestone {
  display: flex;
  /* justify-content: space-between; */
  /* margin-top: 39px; */
  padding: 46px 56px 35px 10px;
  gap: 83px;
}

.milestone-resolution {
  display: flex;
  gap: 69px;
  padding-top: 37px;
}

/* .MuiPaper-rounded {
  min-width: 140px !important;
  min-height: 101px !important;
} */

/* Created MileStone */

.created-milestone-container {
  height: 336px;
  width: 303px;
  border: 1px solid #f4f5f5;
  background-color: #f9fafa;
  padding: 0 10px;
  border-radius: 8px;
  /* position: absolute; */
  display: inline-block;
  margin: 8px;
}

.created-milestone-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.milestone-vertical-ellipsis {
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 32px;
  width: 32px;
  align-items: center;
  background-color: #ffff;
  padding: 4px;
}

.active-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
}

.milestone-type {
  display: flex;
  gap: 67px;
  padding: 17px 17px 7px 17px;
}

.type {
  color: grey;
}

.milestone-target {
  display: flex;
  gap: 24px;
  padding: 0 18px;
}

.milestone-status {
  display: flex;
  gap: 57px;
  padding: 7px 18px;
}

.milestone-parameter {
  display: flex;
  gap: 32px;
  padding: 0 18px;
}

.milestone-color {
  display: flex;
  gap: 60px;
  padding: 7px 18px;
}

.color {
  /* background: #b23977; */
  min-width: 1.4em;
  min-height: 1em;
  display: inline-block;
  border-radius: 4px;
  font-size: 13px;
  font-family: cerebri-sans;
}

.color-section {
  display: flex;
  gap: 8px;
}

.shared-section {
  display: flex;
  position: relative;
  right: 18px;
  align-items: center;
}

.show-more-mile {
  white-space: nowrap;
  color: #619130;
  cursor: pointer;
}

.milestone-use-as {
  display: flex;
  gap: 52px;
  padding: 0 18px;
}

.createdby-date-name {
  padding: 20px;
}

.milestone-visibility {
  display: flex;
  gap: 60px;
  padding: 0 18px 7px 18px;
}

.milestone-resolution-popup {
  height: 31px !important;
  width: 107px !important;
  border-radius: 5px !important;
  position: relative;
  border-bottom: 0.5px solid #f4f5f5;
  padding: 5px 15px;
  cursor: pointer;
}

/* Alert Card Popup */

.alert-card-container {
  height: fit-content;
  min-height: 314px;
  width: 349px;
  margin: auto;
  border: 1px solid#F4F5F5;
  background-color: #ffffff;
  /* border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; */
  border-radius: 8px;
}

.alert-card-heading {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  font-family: Cerebri Sans;
  padding-top: 29px;
  padding-left: 29px;
  padding-right: 26px;
}

.alert-card-para-section {
  padding-top: 10px;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}

.alert-card-footer {
  justify-content: space-between;
  padding: 30px;
  height: 56px;
  border: 1px solid #f4f5f5;
  background-color: #f4f5f5;
  display: flex;
  align-items: center;
  margin-top: 35px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.alert-card-NO {
  height: 38px;
  width: 70px;
  background-color: #ffffff;
  color: black;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
}
.alert-card-REPLACE {
  height: 38px;
  width: 84px;
  background-color: #619130;
  color: #ffff;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
}
.css-zfrihp-MuiSvgIcon-root-MuiSelect-icon {
  /* display: none !important; */
}
.users-dropdown-milestone {
  padding: 15px;
  height: 202px;
}

.mapMarker {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 2px solid #f1664b;
  background-color: rgba(241, 102, 75, 0.5);
  text-align: center;
  padding: 0 5px;
  color: #3f51b5;
  font-size: 16;
  font-weight: bold;
  padding: 4;
  cursor: pointer;
  z-index: 24;
}

.marker-container {
  display: flex;
  height: 428px;
  width: 1052px;
  left: 52px;
  top: 484px;
  background: #ffffff;
  border: 1px solid #f4f5f5;
  border-radius: 8px 8px 0px 0px;
  padding: 25px;
  justify-content: space-between;
  margin: auto;
}

.marker-left-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.table-container {
  overflow-x: auto;
}
