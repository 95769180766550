/* signin header */

.signing-container {
  display: flex;
  margin-bottom: 20px;
}

.signin-sub-container {
  position: relative;
}

.all-prop {
  display: flex;
  background-color: #fff;
  border-radius: 7px;
  padding: 8px 8px 8px 16px;
}

.search-button {
  width: 188px;
  height: 44px;
  border-radius: 8px;
  margin-right: 20px;
  background-color: #fff;
}

.all-unit {
  margin-right: 20px;
  width: 188px;
  height: 44px;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 20px;
  background-color: #fff;
  cursor: pointer;
}

.all-unit-item {
  background-color: #fff;
  width: 188px;
  height: 44px;
  border-radius: 8px;
  padding-right: 10px;
}

.unit-type-region {
  background-color: #fff;
  width: 188px;
  height: 44px;
  border-radius: 8px;
  padding-left: 18px;
}

.all-unit-types {
  display: flex;
  justify-content: space-between;
}

.all-prop-head {
  margin-top: 3px;
}

.all-properties-section {
  position: absolute;
  background: #fff;
  padding: 10px;
  margin-top: 8px;
  border-radius: 8px;
  z-index: 2;
}

.date-section {
  display: flex;
  background-color: #fff;
  border-radius: 7px;
  padding: 10px;
  height: 44px;
}
.date-part {
  display: flex;
  height: 10px;
}

.calendar-section {
  margin-left: 10px 30px !important;
}

.date-picker {
  z-index: 2;
}
.date-position {
  position: absolute;
  right: 0;
}

/* index */

.signings-main-content {
  background-color: #f1f4ef;
  padding: 30px;
  border-radius: 10px;
}

.signin-header {
  margin-bottom: 0px;
}

.signin-table-container {
  margin-top: 0;
}

.signin-table-section {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: fit-content;
  padding: 10px 24px 30px 18px;
}

.moreverticons-span {
  position: relative;
  top: 3px;
  right: 2px;
}
.moreverticons-span-edit {
  position: relative;
  top: 10px;
}
/* .moreverticon {
  background-color: #ffffff;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 44px;
  width: 44px;
} */

.status {
  border-radius: 4px;
  align-items: center;
  width: 57px;
  height: 24px;
  text-align: center;
  padding: 3px;
  border: 1px solid #f4f5f5;
}

/* StatusPopup */

.pop-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 122px;
  background-color: #619130;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  letter-spacing: 1px;
  margin: 0 10px;
}
.pop-container span {
  font-size: 12px;
  font-weight: 500;
}

.horizontal-line {
  border: 1px solid #f4f5f5;
}

.reminder {
  height: 207px;
  width: 384px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #f4f5f5;
}
.reminder-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F9FAFA;
  height: 56px;
  text-align: center;
  padding: 9px 25px;
  cursor: pointer;
}

.reminder-msg {
  display: flex;
  /* justify-content: center; */
  /* top: 20%; */
  justify-content: center;
  align-items: center;
  padding-top: 29px;
  padding-bottom: 24px;
  font-size: 20px;
  margin-bottom: -24px;
}

.reminder-data-name {
  margin-left: 36px;
  margin-bottom: 37px;
  line-height: 25px;
  padding: 20px 0;
}

.btn-btn {
  width: 70px;
  height: 38px;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: black;
  border-radius: 8px;
}

.btn-text {
  text-align: center;
  margin-top: 10px;
}

.btn-btn-green {
  width: 70px;
  height: 38px;
  justify-content: space-between;
  align-items: center;
  background-color: #619130;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
}

.deselect {
  color: #888888;
  font-style: italic;
  margin-left: 139px;
  text-align: right;
}

.starred-container {
  display: flex;
  margin-left: 26px;
}

.group-align {
  padding-top: 10px !important;
}

/* .status-data{
  margin-left: 10px;
} */

.status-properties {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .status-dropdown-data{
  padding: 10px;
} */

.status-container {
  display: flex;
  /* margin-top: 13px; */
}

.dropdown-status-data {
  margin-left: 15px;
}

.button-label {
  display: inline-block;
  min-width: fit-content;
  min-height: fit-content;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.04));
  border-radius: 8px;
  border: 1px solid #f4f5f5;
  line-height: 22px;
  background-color: #ffffff;
}
.button-label div {
  padding: 10px 66px 10px 20px;
  cursor: pointer;
}
.download-hr-signings {
  height: 1px;
  background-color: #f4f5f5;
  border: none;
}
.download-dropdown-signings {
  margin-top: 6px;
  position: absolute;
  right: 130px;
  z-index: 1;
}

.hover-gray {
  text-align: left !important;
  height: 44px;
  /* width: 240px;
  padding: 15px; */
}

.hover-gray:hover {
  background: #f9fafa;
  cursor: pointer;
}

.createdBy-dropdown {
  background: #fff;
  margin-top: 8px;
  border-radius: 8px;
  min-width: fit-content;
  max-height: 323px;
  overflow-y: scroll;
}
.createdBy-dropdown::-webkit-scrollbar {
  display: none;
}

/* Cancel Popup */

.cancel-popup-container {
  height: fit-content;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  /* border: 1px solid #011818; */
  border: 1px solid #f4f5f5;
  margin: auto;
}

.cancel-order-head {
  text-align: center;
  padding: 15px;
  height: 55px;
}

.popup-hrzn-line {
  color: #f4f5f5;
  border: 1px solid #f4f5f5;
}

.cancel-popup-section {
  height: 93px;
  width: 394px;
  background-color: #f9fafa;
  border: 1px solid #f4f5f5;
  border-radius: 6px;
  margin: 17px 25px 0 25px;
}

.cancel-popup-section {
  display: flex;
  justify-content: space-around;
  padding: 20px 40px;
}

.cancel-popup-sub-section {
  position: relative;
  right: 25px;
}

.show-more-section {
  display: flex;
  position: relative;
  right: 5px;
}
.show-more {
  color: #619130;
  cursor: pointer;
}

.show-inherit {
  display: inherit;
}

.plus-one {
  padding: 0 3px 0 0;
}

.upper-table {
  color: #888888;
}

.cancel-comment-head {
  margin: 34px 162px 0 28px;
  white-space: nowrap;
}

.input-comment {
  height: 122px;
  width: 394px;
  margin: 6px 25px 0 25px;
  border-radius: 8px;
  border: 1px solid #f4f5f5;
  background-color: #ffffff;
  padding-bottom: 6.4em;
  padding-left: 9px;
}
.input-comment:focus-visible {
  outline: none;
}

.popup-notify {
  font-weight: 300;
  font-size: 10px;
  position: relative;
  left: 28px;
  font-family: Cerebri Sans;
  padding: 4px 0;
}

.order-cancel-details {
  margin: 18px 65px 0 26px;
  color: #888888;
}

.popup-cancel-btn-section {
  display: flex;
  justify-content: space-between;
  padding: 19px 25px;
  height: 54px;
  background-color: #f9fafa;
  margin-top: 63px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.no-btn-section {
  height: 38px;
  width: max-content;
  border: 1px solid #f4f5f5;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 25px;
  position: relative;
  bottom: 10px;
  cursor: pointer;
}

.yes-btn-section {
  height: 38px;
  width: max-content;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  padding: 9px 23px;
  position: relative;
  bottom: 10px;
  background-color: #619130;
  color: #fff;
  cursor: pointer;
}

/* Edit Signin Order  */

.Edit-signin-container {
  height: fit-content;
  width: 100%;
  /* min-height: 529px; */
  min-width: 1202px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #f4f5f5;
}


.data::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 500px;
}

.data::-webkit-scrollbar-thumb {
  background: #e0e0e0;
}

.Edit-signin-header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
}

.Edit-signin-header-subsection {
  display: flex;
  align-items: center;
  gap: 270px;
  padding-left: 36px;
}

.signin-text {
  color: #888888;
}

.edit-signin-order-hr-line {
  border: 1px solid #f4f5f5;
}

.edit-order-upper-table-section {
  background-color: #f2f6ee;
  margin: 9px 37px 0 35px;
  border-radius: 4px;
  padding: 0 10px;
  align-items: center;
  text-align: center;
  height: 0px;
}

.edit-order-lower-table-section {
  margin-top: 100px;
}

.lowerTableRow-section {
  /* background-color: #f9fafa; */
  border: 1px solid #f9fafa;
  border-radius: 8px;
}

.customer-down-table-section {
  margin-top: 25px;
  padding: 0 35px 0px 33px;
  border-radius: 8px;
}

.zindex {
  z-index: 2 !important;
  background-color: #ffffff;
}

.customer-up-table-section {
  padding: 28px 37px 0 35px;
}

.download-dropdown-Edit-signin {
  margin-top: 10px;
  position: absolute;
  right: 58px;
  z-index: 1;
}

/* Remind Popup */

.Remind-Popup {
  height: 212px;
  width: 384px;
  border-radius: 8px;
  border: 1px solid #f4f5f5;
  background-color: #ffffff;
}

/* .alert-for-remind{
margin-left: 30px;
} */

/* Edit signings popup */
.popup-update-btn-section {
  display: flex;
  position: sticky;
  /* align-items: center; */
  bottom: 1px;
  justify-content: flex-end;
  padding: 23px 25px;
  height: 64px;
  background-color: #f9fafa;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.customer-lower-table-section {
  padding: 0px 35px 40px 35px;
  height: 200px;
  border-radius: 8px;
}
.customer-lower-table-header {
  margin-top: 50px;
  padding: 0px 35px 0px 35px;
  border-radius: 8px;
}
.lower-table-select {
  width: 118px;
  height: 44px;
}
