.bezdnX:focus-visible,
.bODACS:focus-visible,
.hMroIB:focus-visible {
  box-shadow: none !important;
  outline: none;
}
.fPBKQM:hover input:not([disabled]) + div {
  border-color: #f4f5f5 !important;
}

.hGMVtY {
  stroke-width: 2px !important;
}

.parent {
  line-height: 38px;
  margin-left: 10px;
  box-shadow: none !important;
}
.child {
  line-height: 38px;
  margin-left: 15px;
  box-shadow: none !important;
}
.parent:focus-visible {
  outline: none;
}
.child:focus-visible {
  outline: none;
}

.backdrop::backdrop {
  background: rgba(255, 0, 0, 0.25);
}
