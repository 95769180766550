.traffic-property-dropdown {
  height: 44px;
  width: 161px;
  border-radius: 8px;
  background-color: #fff;
  padding: 10px;
  /* box-sizing: border-box #f4f5f5; */
  border-color: #f4f5f5;
}
.traffic-property-dropdown:focus-visible {
  outline: none;
}
select {
  width: 150px;
  padding: 5px 35px 5px 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
option {
  height: 44px;
  width: 161px;
  border-radius: 8px;
  background-color: #fff;
  padding: 10px;
}
/* .traffic-property-options {
  height: 44px;
  width: 161px;
  border-radius: 8px;
  background-color: #fff;
  padding: 10px;
} */
.up-arrow {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  margin-top: auto;
  margin-bottom: auto;

  border-left: 12px solid rgba(167, 198, 144, 1);
}
/* paper */

.paper {
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 188px;
}
.card-wrapper {
  background-color: #fff;
  border-radius: 10px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.header-text {
  display: flex;
}

.question-mark {
  cursor: pointer;
}

.que-mark-icon {
  margin-top: 2px;
  margin-left: 12px;
}
.mixed-traffic-section {
  padding: 0 25px;
  max-height: 1600px;
}

.heatmap-heading {
  margin-left: 2px;
}

.heatmap-container {
  width: 100%;
  margin: auto;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 44px;
}

.region-name {
  margin-left: -5px;
}

.heatmap-btn {
  background-color: #fff;
  padding: 5px;
  width: 103px;
  height: 34px;
  border-radius: 8px;
  border: 1px solid #f4f5f5;
}

.heatmap-btn :hover {
  cursor: pointer;
  font-weight: bolder;
}

/* .MuiPaper-rounded {
  min-width: 240px !important;

  min-width: auto !important;
} */

.heatMap-table-section {
  height: fit-content;
  top: 95px;
  width: 100%;
  padding-left: 16px;
  position: relative;
}

.see-details {
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
}

.more-icon {
  margin: 10px;
  /* border: 1px solid #f4f5f5; */
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
}
.more-icon span {
  position: relative;
  top: 10px;
  left: 10px;
}
.more-icon span svg {
  fill: #619130;
}

.iframe-listview-section {
  position: relative !important;
  bottom: 80px !important;
  padding: 24px;
}

.pop-unit-views {
  color: #619130;
  margin-top: 9px;
}

/* mixedChart */

.apexcharts-legend-series {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #f9fafa;
}
.apexcharts-legend-text {
  font-family: Cerebri Sans !important ;
  line-height: 18px !important;
  color: #000000 !important;
  font-weight: 600 !important;
}
.apexcharts-legend-marker {
  width: 7.45px !important;
  height: 7.62px !important;
  border-radius: 2px !important;
  margin-right: 8px !important;
}
.apexcharts-toolbar {
  display: none !important;
}
.arrow_box {
  width: 151.7px;
  height: 73.22px;
  border-radius: 8px;
}

/* popularUnit popular div css */
.popular-views {
  background-color: #f9fafa;
  padding: 15px 24px;
  margin: 12px 20px;
  border-radius: 4px;
}

/* index */

.popular-com {
  margin-top: 16px;
}

.calendar-container {
  background-color: #f1f4ef;
  padding: 30px 30px 0 30px;
}

.traffic-container {
  background-color: #f1f4ef;
  padding: 20px 30px 10px 30px;
  border-radius: 8px;
}

.property-all-data {
  padding-bottom: 10px;
}

.traffic-platform-card-header {
  background-color: #fff;
  border-radius: 10px;
  height: 482px;
}

.traffic-platform-card-header .traffic-platform-card-title {
  display: flex;
}

.css-1xzqqmo-MuiGrid-root {
  margin-right: 13px;
}

.traffic-platform-card-header .platform-data {
  margin: 10px;
  /* border: 1px solid #f4f5f5; */
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
}
.MuiSwitch-input {
  margin-left: 5px;
}
.platform-data {
  margin: 10px 18px !important;
}

.traffic-platform-card-header .platform-data span {
  position: relative;
  top: 10px;
  left: 10px;
}
.span-text {
  width: max-content;
}

.traffic-overview-card-header {
  background-color: #fff;
  border-radius: 10px;
}

.traffic-overview-card-header .traffic-overview-card-title {
  display: flex;
  margin: 20px;
}

.traffic-overview-card-header .platform-data {
  margin: 20px !important;
  /* padding-left: 20px !important; */
  /* border: 1px solid #f4f5f5; */
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
}

.traffic-overview-status-label {
  min-width: 1em;
  min-height: 1em;
  display: inline-block;
  margin-right: 1em;
  border-radius: 4px;
}

.traffic-overview-card-header .platform-data span {
  position: relative;
  top: 10px;
  left: 10px;
}



.popularviews-card-body.m-24px {
  margin: 24px;
}

.popular-components .card-body {
  padding: 15px;
}

.popular-filters .card-body {
  padding: 24px;
}

.download-dropdown-traffic {
  margin-top: 6px;
  position: absolute;
  right: 82px;
  z-index: 1;
}

.popular-filter-card {
  background-color: #f0f;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 27px;
}

.popular-components-card {
  background-color: #f0f;
  border-radius: 10px;
  margin-top: 27px;
}

.funnel-popup-container {
  width: 682px;
  border-radius: 8px;
  border: 1px solid #f4f5f5;
  margin: auto;
  background-color: #ffffff;
  overflow: hidden;
}

.funnel-popup {
  /* height: 44px !important; */
  width: 530px !important;
  border-radius: 8px !important;
  margin-left: 25px !important;
  margin-top: 35px !important;
  /* padding-left: 16px !important; */
  /* border: 1px solid #f4f5f5 !important; */
  background-color: #ffffff !important;
}

/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 26px !important;
  background-color: #ffffff !important;
  border-radius: 8px !important;
} */

.funnel-texboxes {
  display: flex;
  justify-content: space-around;
  width: 530px;
  border: 1px solid #f4f5f5;
  background-color: #f4f5f5;
  height: 80px;
  align-items: center;
  border-radius: 8px;
  margin-left: 25px;
  margin-top: 24px;
}

.funnel-header {
  justify-content: space-between;
}

.horizonal-line {
  border: 1px solid #f4f5f5;
  color: #f4f5f5;
}

.funnel-namepage {
  height: 44px;
  width: 196px;
  border-radius: 8px;
  border: 1px solid #f4f5f5;
  padding-left: 16px;
}

.user-type-heading {
  align-items: center;
  text-align: center;
}

.funnel-rank {
  margin-left: 7px;
}

.funnel-icons {
  margin-right: 7px;
  margin-top: 5px;
  cursor: pointer;
}
.remove-funnel {
  line-height: 22px;
  /* font-style: italic; */
  display: flex;
  margin-top: 54px;
  margin-left: 35px;
  color: #888888;
  cursor: pointer;
}

.add-step {
  display: flex;
  width: 530px;
  min-height: 44px;
  border-radius: 4px;
  border: 1px dashed #619130;
  margin-left: 25px;
  margin-top: 20px;
  cursor: pointer;
}

.add-step-text {
  color: #619130;
}
.dash-funnel-border {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 197px;
}
.addicon {
  margin-top: 3px;
  color: #619130;
}

.funnel-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F9FAFA;
  height: 56px;
  text-align: center;
  padding: 9px 25px;
  z-index: 1;
  margin-top: 44px;
  width: 100%;
  height: 64px;
  position: sticky;
    bottom: 0;
}

.funnel-btn-btn {
  width: 70px;
  height: 38px;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: black;
  border-radius: 8px;
}

.btn-text-no {
  text-align: center;
  cursor: pointer;
}

.funnel-btn-green {
  width: 70px;
  height: 38px;
  justify-content: space-between;
  align-items: center;
  background-color: #619130;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
}

/* Create New Funnel  */

.new-funnel-container {
  width: 237px;
  height: 257px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #f4f5f5;
  margin-bottom: 20px;
}

.new-funnel-heading {
  width: 237px;
  height: 28px;
  background-color: #888888;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 5px 22px;
}

.funnel-count {
  color: #ffffff;
  line-height: 22px;
}

.funnel-traffic {
  height: 112px;
  width: 237px;
  padding-top: 20px;
  /* background-color: rgba(167, 198, 144, 1); */
}

.traffic-percent {
  display: flex;
  justify-content: center;
  color: #000000;
  font-weight: 700;
  font-size: 50px;
}

.funnel-arrow {
  margin-left: 53px;
  margin-top: 28px;
  position: relative;
}

.funnel-traffic-value {
  color: black;
  display: flex;
  justify-content: space-between;
  /* background-color: #F4F5F5; */
}
.funnel-traffic-value-2 {
  margin-top: 10px;
  color: black;
  display: flex;
  justify-content: space-between;
}

.funnel-value {
  display: flex;
  justify-content: flex-end;
}

.funnel-number {
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
  width: 35px;
}

.funnel-prcnt {
  /* margin-right: 29px; */
  width: 35px;
  display: flex;
  justify-content: flex-end;
}

.funnel-value-str {
  width: 237px;
}

.funnel-traffic-footer {
  color: #ffffff;
  margin: 31px 31px 0px 15px;
}

.delete-funnel {
  display: flex;
  justify-content: space-between;
  margin-top: 31px;
  margin-right: 14px;
}

/* No Funnel created container */
.no-funnel-container {
  background-color: #f9f9f9;
  padding: 27px 0px 27px 20px;
  border-radius: 8px;
  color: #888888;
  margin: 37px 30px 30px 30px;
  color: #888888;
}

/* funnel Page */
.funnel-page-container {
  background-color: #f9fafa;
  /* min-height: 709px; */
  height: fit-content;
  padding: 20px 18px 46px 27px;
  margin: 28px 29px;
  border-radius: 8px;
  border: 1px solid rgba(249, 250, 250, 1);
}

.funnel-page-header {
  display: flex;
  justify-content: space-between;
  /* padding: 25px 18px; */
}
.funnel-visit {
  display: flex;
  /* padding: 0px 18px; */
  margin-top: 10px;
}
.conservation {
  display: flex;
  padding-top: 5px;
}
.cons-rate-prcnt {
  color: #619130;
  margin-left: 4px;
}

.funnel-visit-value {
  margin-left: 4px;
}

.funnel-cards {
  display: flex;
  gap: 7px;
  margin-top: 35px;
  flex-wrap: wrap;
}

.funnel-cards-r2 {
  padding: 20px 15px;
}

/* Heatmap */

.download-property {
  /* margin-top: 6px; */
  /* position: absolute; */
  right: 130px;
  z-index: 1;
}

.dropdown-property-cell {
  display: inline-block;
  min-width: 237px;
  min-height: 47px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.04));
  /* border-radius: 8px; */
  border: 1px solid #f4f5f5;
  line-height: 22px;
  background-color: #ffffff;
  padding: 14px;
}

.download-property :hover {
  background: #f9fafa;
  cursor: pointer;
}
/* Heatamp Override css */
/* .detail-head {
  display: none !important;
}
.detail-head .heatmap-head-top__wrapper {
  display: none !important;
}
.heatmap-head-top__wrapper {
  display: none !important;
} */

/* .scrollable-container-popular-filter {
  height: 388px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.scrollable-container-popular-filter::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 500px;
}

.scrollable-container-popular-filter::-webkit-scrollbar-thumb {
  background: #e0e0e0;
} */

.whatever-you-wanna-add {
  display: flex;
}

