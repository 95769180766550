/* TabMenu */

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab {
  font-weight: 300;
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid transparent;
  position: relative;
  list-style: none;
  padding: 14px 28px;
  cursor: pointer;
  width: auto;
  border-radius: 8px;
  font-size: 14px;
  /* border: 1px solid #f4f5f5; */
}

.react-tabs__tab:not(.react-tabs__tab--selected):hover {
  background: #fff;
  color: #619130;
  border: 1px solid #f4f5f5;
}

.react-tabs__tab--selected {
  background: #fff;
  color: #619130;
  font-weight: 700;
  border: 1px solid #f4f5f5;
}

.react-tabs__tab--disabled {
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.table-card-section {
  padding: 0 24px 24px;
}

.availablity-by-rooms-container {
  background-color: #fff;
  border-radius: 8px;
}
.economy-progress-status {
  display: flex;
}

.property-and-monthly-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 11px;
  gap: px; */
}

.month-property-dropdown {
  display: flex;
  gap: 5px;
  align-items: center;
}

.property-binding-dropdown {
  position: absolute;
  right: 199px;
  top: 68px;
  min-width: 167px;
  /* min-height: -moz-fit-content; */
  min-height: fit-content;
  background-color: #ffffff;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 4%) 0px 1px 1px;
}
.month-binding-dropdown {
  position: absolute;
  right: 82px;
  top: 68px;
  min-width: 120px;
  /* min-height: -moz-fit-content; */
  min-height: fit-content;
  background-color: #ffffff;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 4%) 0px 1px 1px;
}

.economy-moreover-icon {
  margin: 10px;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
}

/* Simpletable */

.container {
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 10px;
  margin: 24px;
}
.analysis-container {
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  margin: 24px;
}

.calendar-container {
  background-color: #f1f4ef;
  padding: 30px 30px 0 30px;
}

.economy-container {
  background-color: #f1f4ef;
  padding: 30px 30px 5px 30px;
  border-radius: 8px;
}

.analysis-card-header {
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 30px;
}

.analysis-card-header .analysis-card-title {
  display: flex;
}

.analysis-card-header .vertical-ellipsis {
  margin: 10px;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
}

.analysis-card-header .vertical-ellipsis span {
  position: relative;
  top: 10px;
  left: 10px;
}

.margin-bottom-4px {
  margin-bottom: 4px;
}

.idleness-card-header {
  background-color: #fff;
  border-radius: 10px;
  margin-top: 30px;
}

.idleness-card-header .idleness-card-title {
  display: flex;
}

.idleness-card-header .vertical-ellipsis {
  margin: 10px;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
}

.idleness-card-header .vertical-ellipsis span {
  position: relative;
  top: 10px;
  left: 10px;
}

.idleness-statuslabel {
  min-width: 1em;
  min-height: 1em;
  display: inline-block;
  margin-right: 1em;
  border-radius: 4px;
}

/* Availability divided by rooms */

.availabilityrooms-card-header {
  background-color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

/* .availabilityrooms-card-header .availabilityrooms-card-title {
  margin: 20px;
} */

.availabilityrooms-card-header .vertical-ellipsis {
  margin: 10px;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
}

.availabilityrooms-card-header .vertical-ellipsis span {
  position: relative;
  top: 10px;
  left: 10px;
}

.availabilityrooms-status-label span {
  min-width: 1em;
  min-height: 1em;
  display: inline-block;
  margin-right: 1em;
  border-radius: 4px;
}

/* Availability divided by square meters */
.availablity-by-square-container {
  background-color: #fff;
  border-radius: 8px;
  margin-left: 9px;
}
/* 
.availablitybysquare-card-header .availablitybysquare-card-title {
  margin: 20px;
} */

.availablitybysquare-card-header .vertical-ellipsis {
  margin: 10px;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
}

.availablitybysquare-card-header .vertical-ellipsis span {
  position: relative;
  top: 10px;
  left: 10px;
}

/* All Properties */
.all-properties-dropdown-dashboard {
  width: 196px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 10px 10px 20px;
  color: #000;
  border: 1px solid #f4f5f5;
}

.all-properties-dropdown .mt-3px {
  margin-top: 3px;
}

/* Calender */
.date-picker-filter {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 10px 10px 20px;
  color: #000;
  border: 1px solid #f4f5f5;
  width: max-content;
  height: 44px;
}

.rdrInputRanges {
  display: none !important;
}
.rdrStaticRanges,
.rdrDateDisplayWrapper,
.rdrStaticRange,
.rdrDateRangeWrapper,
.rdrMonthPicker,
.rdrDateDisplayItem input,
.rdrMonthAndYearPickers select {
  font-family: Cerebri Sans !important;
  background-color: #ffffff !important;
}
.rdrStaticRanges {
  align-items: center;
  font-size: 14px;
}
.rdrStaticRange {
  border-bottom: none !important;
}

/* Milestone */
.map-show-button {
  background-color: #fff;
  text-align: center;
  padding: 9px 0;
  color: #619130;
  cursor: pointer;
  width: auto;
  height: 36px;
  /* border: 1px solid #f4f5f5; */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  /* background-color: aqua; */
  /* padding-top: 35px; */
}

.btn-create-milestone {
  align-items: center;
  background-color: #619130;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  letter-spacing: 0.75px;
  padding: 10px 20px 10px 10px;
}

.btn-section {
  position: absolute;
  width: 88%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  margin: 15px -30px 12px 15px;
  height: 44px;
}

.btn-card {
  max-width: 105px;
  height: 24px;
  background: #f9fafa;
  border-radius: 4px;
  border: 1px solid #f4f5f5;
  display: flex;
  align-items: center;
  padding: 8px;
}

/* .btn-create-milestone {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 188px;
  background-color: #619130;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  letter-spacing: 1px;
  position: absolute;
  z-index: 2;
  margin: 70px 0 0 16px;
} */

.btn-create-milestone .mt-3px {
  margin-top: 3px;
}

.btn-unit {
  background-color: #fff;
  padding: 5px;
  width: 103px;
  height: 34px;
  padding-right: 20px;
  border-radius: 8px;
  border: 1px solid #f4f5f5;
  color: #000;
}
a {
  text-decoration: none;
}

.lbl-reserved {
  background: #fafafc;
  margin-right: 1em;
  border-radius: 4px;
  display: inline-block;
  height: 61px;
  width: 61px;
}

.reserved-container {
  max-width: 926px;
  margin-left: 170px;
  height: 436px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.div-close-button {
  margin: 8px 8px 15px 15px;
  background-color: #f4f5f5;
  border-radius: 8px;
  width: 44px;
  height: 44px;

  cursor: pointer;
}

.div-close-button .mt-7px {
  margin-top: 7px;
}

.div-close-button .ml-6px {
  margin-left: 6px;
}

.lbl-show-more {
  color: #619130 !important;
}

/* Date part in dashboard sec => staticRange Heading code */

.rdrStaticRanges {
  margin-top: 54px;
}
.rdrStaticRange {
  font-size: 14px !important;
}
.rdrStaticRangeLabel {
  line-height: 14px !important;
}
.dateRangeHeading {
  text-align: center;
  padding-top: 26px;
  width: 180px;
  position: absolute;
  color: #000;
}

.rdrMonthAndYearPickers select {
  padding-top: 5px !important;
}

/* side div */

.rdrDefinedRangesWrapper {
  width: 180px !important;
}

.rdrDateDisplayItemActive {
  border-color: currentColor;
  border-radius: 2px !important;
}

/* history popup */

.history-container {
  min-height: 317px;
  min-width: 239px;
  border: 1px solid #f4f5f5;
  padding: 20px 35px 20px 20px;
  border-radius: 8px;
  background-color: #fff;
}

.untilidleness-container {
  min-height: fit-content;
  min-width: fit-content;
  border-radius: 8px;
  background-color: #fff;
  border: "1px solid #f4f5f5";
  font-family: Cerebri sans-light;
  font-size: 13px;
}
