.None {
  visibility: hidden;
}
.date-filter-styling {
  display: flex;
  justify-content: space-between;
}
.img-cal {
  position: relative;
  margin-left: 34px;
  margin-top: 4px;
}

.popup-add-btn-section {
  display: flex;
  position: sticky;
  margin-top: 81px;
  bottom: 0px;
  justify-content: flex-end;
  padding: 19px 25px;
  height: 54px;
  background-color: #f9fafa;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
/* All properties */

.all-unit-dropdown-container {
  align-items: center;
  min-width: fit-content;
  min-height: fit-content;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 17px;
  z-index: 2;
}

.all-regions-dropdown-container {
  right: 28px;
  min-width: fit-content;
  min-height: fit-content;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 17px;
  z-index: 2;
}

.all-regions-dropdown-dropdown {
  width: 161px;
  height: 44px;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 20px;
}

.notesactivity-allactivities-dropdown span {
  top: 3px;
  left: 15px;
}

/* .css-1ugpxlt-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  background-color: #fff0 !important;
} */

.all-properties {
  width: max-content;
  max-height: 560px;
  background-color: #fff;
}
.all-properties-items {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-top: 25px;
}

.MuiOutlinedInput-input {
  padding-bottom: 10px !important;
}

.search-property {
  height: 44px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.all-unit-types {
  width: 188px;
  height: 44px;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  position: relative;
}
.region {
  padding-left: 8px;
}
.all-unit-item {
  /* display: flex;
  justify-content: space-between;
  padding: 10px; */
  /* margin-right: 16px; */
  width: 161px;
  height: 44px;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.horizontal {
  border-top: 1px solid #f4f5f5;
  margin: 20px 0 0 0;
  border-bottom-width: 0px;
}
/* .unit-all-region{
  width: 269px;
} */

.child-container {
  display: flex;
  justify-content: space-between;
}

.starred-container {
  margin-left: 26px;
}

.starred-properties {
  margin-left: 26px;
  margin-top: 26px;
}
.starred-container {
  display: flex;
  margin-top: 13px;
}

.starred-item1 {
  margin-right: 16px;
  margin-top: 9px;
}

.starred-item3 {
  color: #888888;
}

.vertical {
  border-left: 1px solid #f4f5f5;
  position: relative;
  padding-right: 12px;
}
.checkbox-margin {
  position: relative;
  margin-top: -1px;
}

.all-properties-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 47px;
}

.deselect {
  color: #888888;
  font-style: italic;
  margin-left: 134px;
}

.status-box {
  height: 26px;
  width: max-content;
  padding: 4px 12px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  margin-left: 90px;
}
.status-box-text {
  line-height: 18px !important;
}

.textfield fieldset {
  border-color: #f4f5f5;
}

.unit-type {
  width: 188px;
  height: fit-content;
  font-size: 14px;
  position: absolute;
  background: #fff;
  padding: 10px;
  margin-top: 33px;
  border-radius: 8px;
  z-index: 1;
  border: 1px solid #f4f5f5;
  left: 0px;
}
.heading-region {
  margin-left: 17px;
}
.type-all-region {
  width: 269px;
  min-height: 262px;
  font-size: 14px;
  position: absolute;
  background: #fff;
  padding: 10px;
  margin-top: 33px;
  border-radius: 8px;
  z-index: 1;
  border: 1px solid #f4f5f5;
  left: -81px;
}
.all-region {
  width: max-content;
  height: fit-content;
}

/* All Properties */
.starred-all-properties {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.unit-type-dropdown {
  position: absolute;
  margin-top: 8px;
  right: 28px;
  max-width: fit-content;
  min-height: fit-content;
  background-color: #ffffff;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  padding: 17px;
  z-index: 2;
}

.region-dropdown {
  position: absolute;
  background: #fff;
  padding: 10px;
  margin-top: 8px;
  border-radius: 8px;
  z-index: 1;
}

.unit-type-subhead {
  margin-left: 17px;
}

/* overview */
.container-overview {
  background-color: #f1f4ef;
  padding: 30px 30px 1px 30px;
  border-radius: 10px;
}

.textfield-property {
  font-size: 12px;
}

/* .MuiOutlinedInput-input {
  padding: 12.3px 14px !important;
} */

.search-icons {
  font-size: 1px;
}

/* UnitStatus */
.overview-top-section {
  background-color: #f1f4ef;
  margin-top: 30px;
  margin-bottom: 30px;
}

.container-unitstatus {
  background-color: #fff;
  border-radius: 10px;
}

.container-unitstatus .status-label {
  min-width: 1em;
  min-height: 1em;
  display: inline-block;
  margin-right: 1em;
  border-radius: 4px;
  font-weight: 500px;
  font-size: 13px;
  font-family: cerebri-sans;
}

.container-unitstatus .unitstatus-card-header {
  background-color: #fff;
  border-radius: 10px;
}

.unitstatus-card-header .unitstatus-card-title {
  display: flex;
  margin: 24px 0 0 24px;
}

.unitstatus-card-header .vertical-ellipsis {
  margin: 14px;
  margin-bottom: 0;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
}

.unitstatus-card-header .vertical-ellipsis span {
  position: relative;
  top: 10px;
  left: 10px;
}

.download-label {
  display: inline-block;
  width: max-content;
  min-height: fit-content;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.04));
  border-radius: 8px;
  border: 1px solid #f4f5f5;
  padding: 10px 0;
  line-height: 22px;
}
.download-label div {
  padding: 0 66px 0 20px;
  cursor: pointer;
}
.download-hr {
  height: 1px;
  background-color: #f4f5f5;
  border: none;
  margin: 10px 0;
}
.download-dropdown {
  background-color: #ffffff;
  right: 16px;
  z-index: 1;
}
/* StatusActivity */

.all-activity {
  display: flex;
  flex-direction: column;
  width: max-content;
  height: fit-content;
}

.container-statusactivity {
  background-color: #fff;
  border-radius: 10px;
  position: relative;
}

.statusactivity-card-header {
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 15px;
}

.statusactivity-card-header .statusactivity-card-title {
  display: flex;
  margin: 24px 0 0 24px;
}

.statusactivity-card-header .allactivities {
  /* margin: 16px; */
  display: flex;
}

.statusactivity-card-header .statusactivity-allactivities-dropdown {
  margin-top: 16px;
  width: 161px;
  height: 44px;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 10px 20px;
  align-items: center;
}

.statusactivity-card-header .statusactivity-allactivities-dropdown span {
  top: 3px;
  left: 15px;
}

.statusactivity-card-header .dropdown-container {
  position: absolute;
  margin-top: 8px;
  right: 76px;
  min-width: 209px;
  min-height: fit-content;
  background-color: #ffffff;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  padding: 17px;
  box-shadow: rgb(0 0 0 / 4%) 0px 1px 1px;
}

.statusactivity-card-header .vertical-ellipsis {
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
}

.statusactivity-card-header .vertical-ellipsis span {
  position: relative;
  top: 10px;
  left: 10px;
}

.container-statusactivity .card-body {
  margin: 0 20px 10px 24px;
  padding: 16px 24px;
  border-radius: 8px;
  background-color: #f9fafa;
  display: flex;
  justify-content: space-between;
}

.container-statusactivity .status-activity-label {
  border-radius: 8px;
  margin-right: 20px;
  align-items: center;
  width: 100px;
  height: 28px;
  text-align: center;
  padding: 5px;
  border: 1px solid #f4f5f5;
}

.container-statusactivity .thumb {
  margin-right: 10px;
}

/* overview bottom section */
.overview-bottom-section {
  background-color: #f1f4ef;
  margin-bottom: 30px;
}

/* Notes Activity */
.container-notesactivity {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
}

.notesactivity-card-header {
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 15px;
}

.notesactivity-card-header .notesactivity-card-title {
  display: flex;
  margin: 24px 0 0 24px;
}

.notesactivity-card-header .allactivities {
  /* margin: 16px; */
  display: flex;
}

.notesactivity-card-header .notesactivity-allactivities-dropdown {
  margin-top: 16px;
  width: 161px;
  height: 44px;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.notesactivity-card-header .notesactivity-allactivities-dropdown span {
  top: 3px;
  left: 15px;
}

.notes-activity-dropdown-container {
  /* position: absolute; */
  margin-top: 8px;
  right: 75px;
  min-width: fit-content;
  max-height: 224px;
  background-color: #ffffff;
  /* border: 1px solid #f4f5f5; */
  border-radius: 8px;
  padding: 17px;
  box-shadow: rgb(0 0 0 / 4%) 0px 1px 1px;
  overflow-x: hidden;
  overflow-y: auto;
}

.notesactivity-card-header .vertical-ellipsis {
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
}

.notesactivity-card-header .vertical-ellipsis span {
  position: relative;
  top: 10px;
  left: 10px;
}

.container-notesactivity .card-body {
  margin: 0 20px 10px 24px;
  padding: 16px 20px;
  border-radius: 8px;
  background-color: #f9fafa;
  display: flex;
  position: relative;
  gap: 200px;
  align-items: center;
}

.container-notesactivity .card-body .show-more-link {
  color: #619130;
  cursor: pointer;
}
.showmore-card {
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 15px;
  width: 200px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  white-space: break-spaces;
}
.wrap {
  word-wrap: break-word;
}

/* Action Activity */

.container-actionactivity {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  position: relative;
}

.actionactivity-card-header {
  background-color: #fff;
  border-radius: 10px;
}

.actionactivity-card-header .actionactivity-card-title {
  display: flex;
  margin: 24px 0 0 24px;
}

.actionactivity-card-header .allactivities {
  /* margin: 16px; */
  display: flex;
}

.actionactivity-card-header .actionactivity-allactivities-dropdown {
  margin-top: 16px;
  width: 161px;
  height: 44px;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.actionactivity-card-header .actionactivity-allactivities-dropdown span {
  top: 3px;
  left: 15px;
}

.actionactivity-card-header .dropdown-container {
  position: absolute;
  margin-top: 8px;
  right: 75px;
  min-width: fit-content;
  min-height: fit-content;
  background-color: #ffffff;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  padding: 17px;
  box-shadow: rgb(0 0 0 / 4%) 0px 1px 1px;
}

.actionactivity-card-header .vertical-ellipsis {
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 44px;
  width: 44px;
  align-items: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
}

.actionactivity-card-header .vertical-ellipsis span {
  position: relative;
  top: 10px;
  left: 10px;
}

.container-actionactivity .card-content {
  margin: 0 20px 10px 24px;
  padding: 16px 20px;
  border-radius: 8px;
  background-color: #f9fafa;
  display: flex;
  justify-content: space-between;
}

.container-actionactivity .card-content .action-label {
  width: fit-content;
  height: fit-content;
  border-radius: 4px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  line-height: 20px;
  margin-top: 7px;
  padding: 0 15px;
}

.container-actionactivity .card-content .overdue-day-color-text {
  color: #888888;
}

.container-actionactivity .card-content .deadline {
  margin-top: 15px;
}

/* SetMilestone */

.container-milestone {
  width: 1153px;
  height: 500px;
  background-color: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
  margin: auto;
}

.milestone-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  top: 0;
  position: sticky;
  z-index: 3;
  background-color: #ffffff;
}

.close-icon {
  width: 44px;
  height: 44px;
  background: #f4f5f5;
  border-radius: 8px;
}

.hr {
  border: 1px solid #f4f5f5;
}

.milestone-text {
  padding: 30px 30px 0px 30px;
}

.milestone-button {
  display: flex;
  align-items: center;
  position: relative;
  float: right;
  margin-right: 47px;
  border-radius: 8px;
  height: 44px;
  width: 132px;
  background-color: #619130;
  color: #ffffff;
}
.add-icon {
  margin-top: 4px;
}

.parent-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.delete-button {
  font-family: Cerebri Sans;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  color: #888888;
}
.target-date-container {
  display: flex;
  align-items: center;
  margin: 69px 0px 6px 64px;
}
.child-1 {
  width: 258px;
}
.child-2 {
  width: 350px;
}
.child-3,
.child-4,
.child-5 {
  width: 140px;
}
.child-1,
.child-2,
.child-3,
.child-4,
.child-5 {
  font-family: Cerebri Sans;
  font-weight: 500;
  font-size: 13px;
}

.data-container {
  width: 1056px;
  height: 56px;
  background-color: #f9fafa;
  margin: 0 0 10px 20px;
  border-radius: 8px;
  display: flex;
  gap: 17px;
  align-items: center;
  justify-content: center;
  font-family: Cerebri Sans;
  font-weight: 300;
  font-size: 14px;
}

.data-child-1 {
  width: 254px;
}
.data-child-2 {
  width: 200px;
}
.data-child-3,
.data-child-4,
.data-child-5,
.data-child-6 {
  width: 120px;
}
.data-child-1,
.data-child-2,
.data-child-3,
.data-child-4,
.data-child-5,
.data-child-6 {
  background-color: #ffffff;
  border-radius: 8px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  border: 1px solid #f4f5f5;
}

.colorbox {
  min-width: 16px;
  min-height: 16px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 3px;
}

.hr2 {
  border: 1px solid #f4f5f5;
  margin: 45px 0;
}

.target-date-container2 {
  display: flex;
  align-items: center;

  margin: 44px 0px 10px 64px;
  font-weight: 500;
  font-family: Cerebri Sans;
  font-size: 13px;
  width: 895px;
}

.manual-child-1,
.manual-child-2,
.manual-child-3,
.manual-child-4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.manual-child-1 {
  width: 254px;
}
.manual-child-2 {
  width: 200px;
}
.manual-child-3 {
  width: 153px;
}
.manual-data-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 36px;
  font-family: Cerebri Sans;
  font-size: 14px;
  margin-bottom: 28px;
}
.heading {
  width: max-content;
}

.head-unit-type {
  padding-left: 10px;
}

/* Unit Process OverTime */

.unit-process-container {
  height: max-content;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
}

.value {
  position: absolute;
  margin-top: 3px;
  margin-right: 1px;

  right: 6px;
}

.column-subsection {
  position: relative;
  display: inline-block;
  text-align: center;
  height: 20px;
  width: 20px;
  border-radius: 4px;
}

.column-body-section {
  text-align: center;
  height: 20px;
  width: 32px;
  border-radius: 4px;
  padding: 3px;
}

.table-first-row-section {
  display: flex;
  gap: 10px;
}

.unit-process-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 50px 0 20px 0;
  flex-wrap: wrap;
}

.footer-color-section {
  height: 8px;
  width: 8px;
  border-radius: 2px;
  align-items: center;
}

.footer-section {
  height: fit-content;
  white-space: nowrap;
  display: flex;
  width: fit-content;
  background-color: #f9fafa;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  gap: 8px;
  border-radius: 4px;
}

/* Comments Section */

.new-comments-subcontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  height: 64px;
  position: sticky;
  top: 0;
  bottom: 20px;
  background-color: #fff;
}

.add-comment-button-section {
  background-color: rgb(255, 255, 255);
  /* height: 27px; */
  box-sizing: border-box;
  border: none;
  display: flex;
  justify-content: flex-end;
}

.comment-head {
  display: flex;
  width: -moz-fit-content;
  /* width: fit-content; */
  align-items: center;
  justify-content: space-between;
  /* padding: 38px 0; */
  /* margin: 0px 28px 0px 31px; */
  /* gap: 135px; */
  /* margin-left: 84px; */
  /* padding-bottom: 13px; */
  /* padding: 10px 55px; */
}

.InnerChildSection {
  display: flex;
  height: 44px;
  border-radius: 8px;
  align-items: center;
  justify-content: space-around;
  background-color: #f4f5f5;
  margin-bottom: 8px;
}

.comments-hover-gray {
  text-align: left !important;
  height: 44px;
  width: 82px;
}

.comment-head-sss {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  /* padding: 38px 0; */
  gap: 135px;
  margin-left: 84px;
  padding-bottom: 3px;
}

.comments-container {
  height: 67px;
  margin: 0px 28px 0px 31px;
  padding: 25px 0;
  border-radius: 8px;
  background-color: #f9fafa;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #f9fafa;
}

.comments-MoreVertIcon-container {
  border-radius: 8px;
  margin-right: 43px;
}

.comments-date {
  margin-left: 52px;
  white-space: nowrap;
}

.category {
  margin-right: 203px;
}

.comments-vertical-ellipsis {
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  height: 36px;
  width: 36px;
  align-items: center;
  background-color: #ffff;
}

.comments-moreverticons-span {
  position: relative;
  right: 6px;
  bottom: 2px;
}

.comments-container:nth-child(even) {
  background: #fff;
  border: none;
}

/* Add comments */

.Add-comments-container {
  height: 500px;
  /* position: relative; */
  background-color: #fff;
}

.add-commments-input-section {
  display: flex;
  align-items: center;
  padding-top: 39px;
  padding-left: 30px;
  gap: 214px;
}
.add-commments-comment-data-section {
  display: flex;
  justify-content: space-between;
  padding: 0 110px;
}

.input-add-comments {
  height: 122px;
  width: 436px;
  margin: 6px 25px 0 25px;
  border-radius: 8px;
  border: 1px solid #f4f5f5;
  background-color: #ffffff;
  padding-bottom: 6.4em;
  padding-left: 9px;
}

.input-add-comments:focus-visible {
  outline: none;
}

.add-commments-title-section {
  display: flex;
  align-items: center;
  padding-top: 39px;
  padding-left: 30px;
  gap: 247px;
}

.add-commments-desc-section {
  display: flex;
  align-items: center;
  padding-top: 39px;
  padding-left: 30px;
  gap: 177px;
}

.step-line {
  width: 40px;
  background-color: #fff;
  height: 1px;
  margin-top: 31px;
  opacity: 0.4;
  flex-shrink: 0;
}


/* mui input text style */
.form-input {
  position: relative;
  width: 100% !important;
  font-family:  Cerebri sans-light !important;
}

.form-input input::placeholder {
  opacity: 0;
}

.form-input input {
  width: 100% !important;
  font-family:  Cerebri sans-light !important;
  /* font-weight: 300; */
  height: 40px;
  font-size: 14px !important;
  padding: 12px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.form-input label {
  font-family: Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000000;
  letter-spacing: 0.3px;
  transition: 0.1s;
  /* font-weight: 300 !important; */
  padding: 0px 4px 0px 4px !important;
}

.form-input input:focus+label,
.form-input input:not(:placeholder-shown)+label {
  top: 0;
  font-size: 8px !important;
  letter-spacing: 0.25px;
  font-family: Cerebri sans-medium !important;
  color: #888888;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(247,247,247,0) 50%);
  padding: 4px;
  /* font-weight: 300;  SV-20230516 - not needed as per figma */
}

.form-input input:focus {
  border: 1px solid #888;
}

.form-input input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}
.form-input input:disabled{
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}
.form-input input:-webkit-autofill,
.form-input input:-webkit-autofill:hover,
.form-input input:-webkit-autofill:focus,
.form-input input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000;
}
/* .form-input input:disabled + label{
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
} */

/*------------------ size big input text  ---------------------*/
.form-input-big {
  position: relative;
  width: 100% !important;
  font-family:  Cerebri sans-light !important;
}

.form-input-big input::placeholder {
  opacity: 0;
}

.form-input-big input {
  width: 100% !important;
  font-family:  Cerebri sans-light !important;
  height: 60px !important;
  font-size: 16px !important;
  letter-spacing: 0.3px;
  padding: 10px;
  outline: none;
  border: 1px solid #d1d1d1 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
}

.form-input-big label {
  font-family:  Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px !important;
  color: #000;
  letter-spacing: 1px;
  transition: 0.1s;
}

.form-input-big input:focus+label,
.form-input-big input:not(:placeholder-shown)+label {
  top: 0;
  font-size: 12px !important;
  color: #888;
  font-family: Cerebri sans-medium !important;
  letter-spacing: 0.25px;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(247,247,247,0) 50%);
  padding: 4px;
}

.form-input-big input:focus {
  border: 1px solid #888;
}

.form-input-big input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}
.form-input-big input:disabled{
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.form-input-group {
  position: relative;
  width: 100% !important;
  font-family:  Cerebri sans-light !important;
}

.form-input-group input::placeholder {
  opacity: 0;
}

.form-input-group input {
  width: 100% !important;
  font-family:  Cerebri sans-light !important;
  height: 40px;
  letter-spacing: 0.25px;
  font-size: 14px !important;
  padding: 12px !important;
  outline: none;
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  border-radius: 8px;
}

.form-input-group label {
  font-family:  Cerebri sans-light !important;
  position: absolute;
  left: 10px;
  top: 50%;
  letter-spacing: 0.25px;
  transform: translateY(-50%);
  font-size: 14px !important;
  color: #000;
  transition: 0.1s;
}

.form-input-group input:focus+label,
.form-input-group input:not(:placeholder-shown)+label {
  top: 0;
  font-size: 8px !important;
  color: #888;
  background: rgb(255,255,255);
  letter-spacing: 0.25px;
  background: linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(247,247,247,0) 50%);
  padding: 4px;
  font-family: Cerebri sans-medium !important;
}

.form-input-group input:focus {
  border: 1px solid #d1d1d1;
}

.form-input-group input:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}
.form-input-group input:disabled{
  background-color: #fff;
  cursor: not-allowed;
  color: #888888;
}

.form-input-group-gray {
  background-color: #f0f0f0;
  border: 1px solid #d1d1d1;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  right: 10px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Cerebri sans-semibold;
}

.form-input-group-gray:hover {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}
