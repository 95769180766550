.parent {
  margin-bottom: 9px;
  margin-left: 10px;
  padding-right: 10px;
}
.children {
  margin-left: 10px;
  font-size: 12px;
  margin-bottom: 35px;
}

.box {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #f4f5f5;
  border-radius: 8px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  padding: 0px 8px;
  z-index: 1;
  width: 373px;
  position: absolute;
}

.question-mark {
  margin-top: 4px !important;
  margin-left: 17px !important;
}

.button {
  border: none;
  /* background-color: white; */
  cursor: pointer;
}
.button:focus-visible {
  outline: none;
  box-shadow: none;
}
