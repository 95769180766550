.bezdnX,
.bODACS {
  box-shadow: none !important;
}
.fPBKQM:hover input:not([disabled]) + div {
  border-color: #f4f5f5 !important;
}

.hGMVtY {
  stroke-width: 2px !important;
}

.parent {
  line-height: 38px;
  margin-left: 10px;
}
.child {
  line-height: 38px;
  margin-left: 27px;
}
